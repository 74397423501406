import { NavbarConfig } from "@energylab/shared-components/constants/config/navbarConfig";

export const navbarConfig: NavbarConfig = {
    navbarLogo: "https://res.cloudinary.com/energylab/image/upload/v1645461786/q8/Logo%20V2/BeWell_2019_icon_pos.jpg",
    fullWidth: false,
    navbarItems: [
        {
            id: "bewellBody",
            translationKey: "menuItem.bewellBody",
            redirectTo: { path: "bewellbody" }
        },
        {
            id: "bewellMind",
            translationKey: "menuItem.bewellMind",
            redirectTo: { path: "bewellmind" }
        },
        // {
        //     id: "about",
        //     translationKey: "menuItem.about",
        //     redirectTo: { path: "about" }
        // },
        {
            id: "challenges",
            translationKey: "menuItem.challenges",
            redirectTo: { path: "challenges" }
        },
        // {
        //     id: "ambassadors",
        //     translationKey: "menuItem.ambassadors",
        //     redirectTo: { path: "ambassadors" }
        // },
        {
            id: "events",
            translationKey: "menuItem.events",
            redirectTo: { path: "events" }
        },
        // {
        //     id: "gallery",
        //     translationKey: "menuItem.gallery",
        //     redirectTo: { path: "gallery" }
        // },
        {
            id: "news",
            translationKey: "menuItem.news",
            redirectTo: { path: "news" }
        },
        {
            id: "faq",
            translationKey: "menuItem.faq",
            redirectTo: { path: "faq" }
        }
    ]
};
