import { ProfileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { newProfileStructure } from "../profileStructure";

export const profileConfig: ProfileConfig = {
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex",
        "nationality",
        "locale",
        "department"
    ],
    structure: newProfileStructure
};
